import React, { useEffect } from 'react';
import { fade } from '../helpers/helpers'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import Footer from '../components/footer';

import logo from '../images/static/logo-800.gif';

// markup
const Contact = ({data, transitionStatus}) => {
  const { scroll } = useLocomotiveScroll()

  useEffect(() => {
    if (scroll && transitionStatus === 'entering') {
      scroll.scrollTo(0, { duration: 0, easing: 0, disableLerp: true });
      scroll.update();
    }
  }, [transitionStatus]);


  useEffect(() => {
    fade('main');
  }, []);

  return (
    <>
      <Footer isContact={true} />
    </>
    )
}

export default Contact


export function Head({ location, params, data, pageContext }) {
  const url = typeof window !== `undefined` ? window.location.href : 'https://madebymade.pl/contact';
  
  return (
    <>
      <title>Contact - MADE - Design. Brand. Technology.</title>
      <meta name="description" content="We'd love to hear from you. hello@madebymade.pl" />
      <meta name="og:description" content="We'd love to hear from you. hello@madebymade.pl" />
      <meta name="og:image" content={logo} />
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={url} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="madebymade.pl" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content="Contact - MADE" />
      <meta name="twitter:description" content="We'd love to hear from you. hello@madebymade.pl" />
      <meta name="twitter:image" content={logo} />
    </>
  )
}